import React, { useState, useEffect, useRef } from 'react'
import { GlobalContext } from './GlobalContext'
import logo from './logo.svg'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useParams,
  useNavigate,
  json,
} from "react-router-dom"
import { publish } from "./utils/pubsub"
import brandstylesjson from './utils/brandstyles.json'
import CloudformationOutputs from './CloudformationOutputs.json'
import FullScreenFridgeScanner from './components/FullScreenFridgeScanner'
import SubscriptionExpired from './components/SubscriptionExpired'
//@ts-ignore
import AiNumberReader from './AiNumberReader.worker.js';
import SubscriptionExpiring from './components/SubscriptionExpiring'
import { checkIfSubscriptionExpiringWithoutPayment } from './utils/checkIfSubscriptionExpiringWithoutPayment'
import Home from './components/Home'
import { softmax } from '@tensorflow/tfjs'

type ObjectAny = {
  [key: string]: any
}

function App() {
  const [loggedInState, setLoggedInState] = useState<string | null>(null)
  const loggedInStateRef = useRef<string>('')
  const [assetToFindOrgFrom, setAssetToFindOrgFrom] = useState<string | null>(null)
  const [idToken, setIdToken] = useState<string | null>(null)
  const [accessToken, setAccessToken] = useState<string | null>(null)
  const [socket, setSocket] = useState<WebSocket | null>(null)
  const [connectionState, setConnectionState] = useState('disconnected')
  const [orgSettings, setOrgSettings] = useState('disconnected')
  const [tableData, setTableData] = useState<ObjectAny | null>(null)
  const [userData, setUserData] = useState<ObjectAny | null>(null)
  const [currentOrganisation, setCurrentOrganisation] = useState<string | null>(null)
  const loginUrl = `https://${CloudformationOutputs.UserPoolDomain}.auth.${CloudformationOutputs.AWSRegion}.amazoncognito.com/login?client_id=${CloudformationOutputs.UserPoolClientId}&response_type=token&scope=email+openid+phone+profile&redirect_uri=${window.location.origin}/dashboard/`
  const logoutUrl = `https://${CloudformationOutputs.UserPoolDomain}.auth.${CloudformationOutputs.AWSRegion}.amazoncognito.com/logout?client_id=${CloudformationOutputs.UserPoolClientId}&logout_uri=${window.location.origin}/`
  const WebSocketURI = CloudformationOutputs.WebSocketURI
  const brandstyles: ObjectAny = brandstylesjson['temperaturelogging']
  const [fridges, setFridges] = useState<ObjectAny | null>(null)
  const [groups, setGroups] = useState<ObjectAny | null>(null)
  const [currentGroupId, setCurrentGroupId] = useState<string | null>(null)
  const [lastCodeDetected, setLastCodeDetected] = useState<string | null>(null)
  const [codeFromUrl, setCodeFromUrl] = useState<string | null>(null)
  const [checkingTimesForGroups, setCheckingTimesForGroups] = useState<ObjectAny | null>(null)
  const [subscriptionHasBeenChecked, setSubscriptionHasBeenChecked] = useState<boolean>(false)
  const [subscriptionExpired, setSubscriptionExpired] = useState<boolean>(false)
  const [subscriptionExpiringWithoutPayment, setSubscriptionExpiringWithoutPayment] = useState<boolean>(false)
  const [aiImageWorker, setAiImageWorker] = useState<any>(null)
  const [unknownCodeLoginOptions, setUnknownCodeLoginOptions] = useState<any>(null)
  const [webPushPublicKey, setWebPushPublicKey] = useState<any>(null)
  const [webPushPermissionState, setWebPushPermissionState] = useState<string>('app_starting')


  const fridgesRef = useRef(null)
  const tableDataRef = useRef<any>(null)
  const socketRef = useRef<WebSocket | null>(null)
  const isIphone = window.navigator.userAgent.includes('iP')
  const isIos = window.navigator.userAgent.includes('Mac OS')

  function urlBase64ToUint8Array(base64String: string) {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
      .replace(/\-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }


  useEffect(() => {
    const web_push_preference = localStorage.getItem('webPushPreference')
    if (web_push_preference === null) {
      localStorage.setItem('webPushPreference', 'user_not_in_trial')
    }
    if (web_push_preference == 'user_in_trial') {
      setWebPushPermissionState('user_in_trial')
    }

  }, [])

  useEffect(() => {
    // console.log(`🇵🇬 login state is now: ${loggedInState}`)
    loggedInStateRef.current = loggedInState || ''
  }, [loggedInState])

  useEffect(() => {
    socketRef.current = socket
  }, [socket])

  useEffect(() => {

    if (webPushPermissionState == 'permission_requested') {
      Notification.requestPermission()
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('./service-worker.js').then(function (registration) {
          // Registration was successful
          console.log('ServiceWorker registration successful with scope: ', registration.scope);

        }, function (err) {
          // registration failed :(
          console.log('ServiceWorker registration failed: ', err);
        });
      }



      navigator.serviceWorker.ready.then(function (registration) {
        // Use the PushManager to subscribe to push messages.
        const subscribeOptions = {
          userVisibleOnly: true,
          //  applicationServerKey: urlBase64ToUint8Array('BNPmF2qAs7Mlg7AWerNfqnF9eyggA5xmBs0gYSgkKyJDtB8m9BMW-org-H8ZN1w_PUuYRR6UTxOKOVZo40DS89M')
          applicationServerKey: urlBase64ToUint8Array(webPushPublicKey)
        };

        registration.pushManager.subscribe(subscribeOptions).then(function (pushSubscription) {
          const websocketRegistrationPayload = JSON.stringify({
            'action': 'fridgetemps',
            'fridgeAction': 'registerPushNotificationSubscription',
            pushSubscription
          })
          sendMessageToWebsocket(websocketRegistrationPayload)
          console.log('Received PushSubscription: ', JSON.stringify(pushSubscription));
          localStorage.setItem('webPushPreference', 'registered')

          // TODO: Send the pushSubscription object to your server
        });
      });
    }
  }, [webPushPublicKey, webPushPermissionState])

  // To do - postpone this until after we're sure we're not about to be sent to a cognito login screen
  useEffect(() => {
    if (aiImageWorker == null) {
      if (localStorage.getItem("force_cpu_for_tf") === null) {
        if (isIphone || isIos) {
          localStorage.setItem("force_cpu_for_tf", "false") // set this back to true if iphones create trouble
        } else {
          localStorage.setItem("force_cpu_for_tf", "false")
        }
      }
      const force_cpu = localStorage.getItem('force_cpu_for_tensorflow') == 'true'
      const worker = new AiNumberReader()
      setAiImageWorker(worker)
      worker.postMessage({ "action": "setForceCpu", force_cpu })
    }
  }, [])

  useEffect(() => {
    if (tableData) {
      tableDataRef.current = tableData
      checkIfSubscriptionExpiringWithoutPayment(tableData, subscriptionHasBeenChecked, setSubscriptionExpiringWithoutPayment, setSubscriptionHasBeenChecked)
    }
  }, [tableData, subscriptionHasBeenChecked])

  useEffect(() => {
    //@ts-ignore
    fridgesRef.current = fridges
  }, [fridges])


  useEffect(() => {
    //console.log("Trying to connect to websocket")
    setConnectionState('tryToConnect')
  }, [])



  useEffect(() => {
    //console.log("[connectionState, socket, loggedInState] useEffect")

    if (
      loggedInState !== 'loggedIn' &&
      loggedInState !== 'guest' &&
      loggedInState !== 'orgUnknown' &&
      loggedInState !== 'guestLoginPrompt'
    ) {
      tryLoginAtStartup()
    }

    if (socket && connectionState === 'connected' && (loggedInState === 'loggedIn' || loggedInState === 'guest')) {
      fetchAllData()
    }

  }, [connectionState, socket, loggedInState])


  useEffect(() => {
    if (idToken && accessToken && socket) {
      // sending tokens to websocket!
      const payloadObj: { [index: string]: string } = {
        action: "login",
        idToken: idToken,
        accessToken: accessToken
      }
      const selectedOrgId = localStorage.getItem("selectedOrgId")
      if (selectedOrgId) {
        payloadObj['selectedOrgId'] = selectedOrgId
      }
      const payload = JSON.stringify(payloadObj)
      sendMessageToWebsocket(payload)
    }
  }, [idToken, accessToken, socket, currentOrganisation])




  useEffect(() => {

    switch (connectionState) {
      case 'closed':
      case 'error':
        setLoggedInState(null)
        setConnectionState('waitThenReconnect')
        setTimeout(() => {
          setConnectionState('tryToConnect')
        }, 200)
        break;
      case 'tryToConnect':
        setConnectionState('connecting')
        const newSocket = new WebSocket(WebSocketURI)
        newSocket.onopen = () => {
          console.log("🔌  socket connected")
          setSocket(newSocket)
          setConnectionState('connected')
        }
        newSocket.onclose = () => {
          console.log("🔌  socket closed")
          setConnectionState('closed')
          setLoggedInState(null)
        }
        newSocket.onerror = () => {
          setConnectionState('error')
          console.log("🔌 socket error")
          setLoggedInState(null)
        }
        break;

    }

  }, [connectionState])




  useEffect(() => {
    if (groups) {
      if (lastCodeDetected) {
        for (const [id, group] of Object.entries(groups)) {
          if (group && group['Fridges']) {
            const fridges_in_this_group = Object.keys(group['Fridges'])
            // console.log(fridges_in_this_group)
            // console.log(lastCodeDetected)
            if (fridges_in_this_group.indexOf(lastCodeDetected) !== -1) {
              setCurrentGroupId(id)
            }
          }
        }
      } else if (codeFromUrl) {
        let group_found = false
        for (const [id, group] of Object.entries(groups)) {
          if (group && group['Fridges']) {
            const fridges_in_this_group = Object.keys(group['Fridges'])
            // console.log(fridges_in_this_group)
            // console.log(lastCodeDetected)
            if (fridges_in_this_group.indexOf(codeFromUrl) !== -1) {
              setCurrentGroupId(id)
              group_found = true
            }
          }
        }
        if (!group_found) {
          console.log("No group found for code")
          const defaultGroup = Object.keys(groups)[0]
          setCurrentGroupId(defaultGroup)
        }
      } else {
        //console.log("got groups but no code detected ")
        //console.log(groups)
        const defaultGroup = Object.keys(groups)[0]
        //console.log(defaultGroup)
        setCurrentGroupId(defaultGroup)
      }
    }

  }, [lastCodeDetected, groups, codeFromUrl])


  useEffect(() => {
    // handle incoming messages
    if (socket !== null) {
      actOnMessage()
    }
  }, [socket])

  useEffect(() => {
    const selectedOrgId = window.localStorage.getItem('selectedOrgId')
    if (selectedOrgId) {
      setCurrentOrganisation(selectedOrgId)
    }
  }, [socket])


  useEffect(() => {
    if (socket && assetToFindOrgFrom) {
      const stickerId = assetToFindOrgFrom
      setUnknownCodeLoginOptions(null)

      const payloadObj: { [index: string]: string } = {
        action: "guestLogin",
        'guestLoginAction': 'requestLoginActionForSticker',
        'stickerId': stickerId
      }
      const payload = JSON.stringify(payloadObj)
      sendMessageToWebsocket(payload)
    }
  }, [socket, assetToFindOrgFrom])


  const tryLoginAtStartup = () => {
    //console.log(`🥏 Trying to log in....`)
    const localGuestLogin = localStorage.getItem("guestLogin")
    const localIdToken = localStorage.getItem("localIdToken")
    const localAccessToken = localStorage.getItem("localAccessToken")
    const queryStringParams = window.location.hash.replace("#", "").split(/[&=]+/)
    let idToken = null
    let accessToken = null
    let assetId = null

    const indexOfIdToken = queryStringParams.indexOf('id_token')
    if (indexOfIdToken >= 0) {
      idToken = queryStringParams[indexOfIdToken + 1]
    }
    const indexOfAccessToken = queryStringParams.indexOf('access_token')
    if (indexOfAccessToken >= 0) {
      accessToken = queryStringParams[indexOfAccessToken + 1]
    }
    const path = window.location.pathname
    if (path.length > 3 && path.length < 10) {
      const cleaned_path = path.split('/')[1].split('?')[0]
      assetId = cleaned_path
      setCodeFromUrl(cleaned_path)
    }

    // There are 3 possible scenarios for cognito
    //
    // 1 - access token and ID token are on the url line -
    //    that means user has just been redirect here by cognito
    //    so we need to save the credentials locally and then use the websocket to log in
    //    login state = TryingNewTokens
    //
    //  2 - access token and id token are in local storage
    //    try using them to log in right now with the websocket
    //    if login fails the handler for that will deal with next steps
    //    login state = TryingSavedTokens
    //  3 - if there is a guest login token in localstorage,
    //      try logging in with that
    //  4 - no access token or id token
    //    we need to try to find out what the login options are
    //    if a code is specified on the url then we can try getting the org from that
    //    and login state = GettingOptionsFromAssetId
    //    if we have no asset id then login state = OrgUnknown

    if (idToken && accessToken) {
      setLoggedInState('TryingNewTokens')
      setIdToken(idToken)
      setAccessToken(accessToken)
      localStorage.setItem('localIdToken', idToken)
      localStorage.setItem('localAccessToken', accessToken)
      const path_before_login = localStorage.getItem('pathBeforeLogin')

      if (path_before_login) {
        console.log('➡️ redirecting to path from before login')
        window.location.href = path_before_login
      } else {
        console.log("➡️ no saved path, going to dashboard ")
        //       window.location.href = '/dashboard'
      }
    } else if (localIdToken && localAccessToken) {
      const path_before_login = window.location.pathname
      if (path_before_login != '/' && path_before_login != '/dashboard') {
        localStorage.setItem('pathBeforeLogin', path_before_login)
      }
      setIdToken(localIdToken)
      setAccessToken(localAccessToken)
    } else if (localGuestLogin) {
      //console.log('Local guest token found, attempting guest login')
      const localGuestDetails = JSON.parse(localGuestLogin)
      const localGuestId = localGuestDetails['Id']
      const localGuestOrganisationId = localGuestDetails['OrganisationId']
      const localGuestUserName = localGuestDetails['UserName']
      const payload = JSON.stringify({
        action: "guestLogin",
        guestLoginAction: "login",
        localGuestDetails: localGuestDetails
      })
      sendMessageToWebsocket(payload)
    }
    else {
      // no access tokens in local storage or url line
      const path_before_login = window.location.pathname
      localStorage.setItem('pathBeforeLogin', path_before_login)

      if (assetId === null) {
        setLoggedInState("orgUnknown")
      } else {
        setLoggedInState("findingOrgFromAssetIdInURL")
        setAssetToFindOrgFrom(assetId)
      }
      //     window.location.href = loginUrl
    }
  }

  const fetchAllData = () => {
    //  console.log(`🥝 fetching all data`)
    let requestAssets = JSON.stringify({
      action: "fridgetemps",
      fridgeAction: "fetchMultiTableData",
      idToken: idToken,
      accessToken: accessToken,
      tableNames: ["Assets", "Groups", "AssetProfiles"]
    })
    sendMessageToWebsocket(requestAssets)

    let requestFridgeGroups = JSON.stringify({
      action: "fridgetemps",
      fridgeAction: "requestFridgeGroups"
    })

    sendMessageToWebsocket(requestFridgeGroups)

    let requestCheckingTimes = JSON.stringify({
      action: "fridgetemps",
      fridgeAction: "requestCheckingTimes"
    })
    sendMessageToWebsocket(requestCheckingTimes)

    let requestBuildVersion = JSON.stringify({
      action: "fridgetemps",
      fridgeAction: "requestLatestBuildVersion"
    })
    sendMessageToWebsocket(requestBuildVersion)

    let requestPushKeyPayload = JSON.stringify({
      action: "fridgetemps",
      fridgeAction: "getVapidKeyForWebPush"
    })
    sendMessageToWebsocket(requestPushKeyPayload)


  }



  const switchCurrentOrganisation = (organisationId: string, clearLoggedInState = false) => {
    console.log(`🇵🇪 Organization id change requested to ${organisationId}`)
    localStorage.setItem("selectedOrgId", organisationId)
    setCurrentOrganisation(organisationId)
    if (clearLoggedInState){
    setSubscriptionHasBeenChecked(false)
    setSubscriptionExpiringWithoutPayment(false)
    setSubscriptionExpired(false)
    setCurrentGroupId(null)
    setLoggedInState(null)
    setTableData(null)
    }

    if (idToken && accessToken && socket) {
      const payloadObj: { [index: string]: string } = {
        action: "login",
        idToken: idToken,
        accessToken: accessToken,
        selectedOrgId: organisationId
      }
      const payload = JSON.stringify(payloadObj)
      sendMessageToWebsocket(payload)
    }
  }

  const sendMessageToWebsocket = async (payload: string) => {
    if (socketRef.current) {
      //console.log(`sending to websocket: ${JSON.parse(payload).action}`)
      socketRef.current.send(payload)
    } else {
      console.log('🚫  Can not send to web socket')
    }
  }

  function timeDifferenceInMilliseconds(timestamp: string): number {
    // Parse the input timestamp
    const inputTime = new Date(timestamp);

    // Get the current time
    const currentTime = new Date();

    // Calculate and return the difference in milliseconds
    return Math.abs(inputTime.getTime() - currentTime.getTime());
  }

  const workOutNextLoginAction = (current_state: string, login_options: string, org_id: string | null) => {
    // the app should try to log in with existing tokens and tokens from the URL line etc before trying to find the org from the sticker/asset id
    // therefore if state is findingOrgFromAssetIdInURL we arent going to be able to just switch to another org

    // this happens if you start up the app with an asset id on the url
    if (current_state == 'findingOrgFromAssetIdInURL') {
      if (login_options.includes("guest")) {
        console.log("Start guest login with asset id")
        return 'start_guest_login'
      } else if (login_options.includes("cognito")) {
        console.log("Login is only with cognito")
        return 'redirect_to_cognito_login_page'
      }
    }
    // this is
    if (current_state == 'orgUnknown') {
      if (login_options.includes("guest")) {
        console.log("Start guest login with asset id")
        return 'start_guest_login'
      } else if (login_options.includes("cognito")) {
        console.log("Login is only with cognito")
        return 'redirect_to_cognito_login_page'
      }
    }


    if (current_state == 'loggedIn') {
      console.log("working out next action for logged in user")
      if (org_id) {
        return 'offer_to_switch_to_available_org'
      } else {
        if (login_options.includes("cognito") && login_options.includes("guest")) {
          return 'offer_both'
        } else if (login_options.includes("cognito")) {
          return 'offer_cognito'
        } else if (login_options.includes("guest")) {
          return 'offer_guest'
        }
      }
    }

  }

  const handleSupportedLoginTypes = (payload: any) => {
    console.log("Got supporeted login types")
    console.log(payload)
    if (payload['supportedLoginTypes'] && payload['supportedLoginTypes']['Fridges']) {
      const login_state = loggedInStateRef.current
      const login_options = payload['supportedLoginTypes']['Fridges']
      console.log(`Supported login options for fridges are`)
      console.log(login_options)
      console.log("current logged in state is")
      console.log(login_state)

      const next_action = workOutNextLoginAction(login_state, login_options, payload['org_id'] || null)
      switch (next_action) {
        case 'start_guest_login':
          setLoggedInState('guestLoginPrompt')
          break
        case 'redirect_to_cognito_login_page':
          window.location.href = loginUrl
          break
        case 'offer_guest':
          setUnknownCodeLoginOptions({ types: ['guest'] })
          break
        case 'offer_cognito':
          setUnknownCodeLoginOptions({ types: ['cognito'] })
          break
        case 'offer_both':
          setUnknownCodeLoginOptions({ types: ['guest'] })
          break
        case 'offer_to_switch_to_available_org':
          setUnknownCodeLoginOptions({ types: ['switch'], org_id: payload['org_id'] })
          break

        default:
          console.log(`unkown next action ${next_action}`)
          break
      }
    } else if (payload['error']) {
      setUnknownCodeLoginOptions({ types: ['error'], error: payload['error'] })

    }
  }

  const actOnMessage = () => {
    if (socket && socket.onmessage === null) {
      socket.onmessage = ({ data }: any) => {
        if (data) {
          const action = JSON.parse(data).action
          const payload = JSON.parse(data).payload
          // console.log(`💌 message received: ${action}`)
          publish(action, payload)

          switch (action) {
            case 'autherror':
              console.log('🚫  Authentication error - logging you out')
              window.location.href = logoutUrl
              break
            case 'supportedLoginTypes':
              handleSupportedLoginTypes(payload)
              break
            case 'loggedIn':
              setLoggedInState('loggedIn')
              setUserData(JSON.parse(data).body.idTokenDecoded.payload)
              setOrgSettings(JSON.parse(data).body.settings)
              break

            case 'confirmGuestConnection':
              console.log('✅ guest login sucessful')
              setLoggedInState('guest')
              console.log('logged in state should have been updated...')
              payload.OrganisationId && switchCurrentOrganisation(payload.OrganisationId)
              localStorage.setItem('guestLogin', JSON.stringify(payload))
              const userData = {
                "name": payload.UserName,
                organisationsForUser: JSON.stringify({
                  [payload.OrganisationId]: payload.OrganisationName
                })
              }
              console.log('setting user data')
              setUserData(userData)
              console.log('set user data')
              break

            case 'refuseGuestConnection':
              console.log('⛔️ guest login not sucessful')
              localStorage.removeItem('guestLogin')
              break
            case 'returnCheckingTimes':
              // console.log('✅ check times returned:')
              // console.log(payload)
              const times_to_recheck = []
              for (const [key, check] of Object.entries(payload)) {
                //@ts-ignore
                const current_check: any = check['current_check']
                if (Object.keys(current_check).indexOf('next_check_starts_at') !== -1) {
                  if (times_to_recheck.indexOf(current_check['next_check_starts_at']) === -1) {
                    times_to_recheck.push(current_check['next_check_starts_at'])
                  }
                }
                if (Object.keys(current_check).indexOf('check_finishes_at') !== -1) {
                  if (times_to_recheck.indexOf(current_check['check_finishes_at']) === -1) {
                    times_to_recheck.push(current_check['check_finishes_at'])
                  }
                }
              }

              for (const time_string of times_to_recheck) {
                const time_in_milliseconds = timeDifferenceInMilliseconds(time_string)

                // setInterval( ()=> {
                //   const time_in_milliseconds = (timeDifferenceInMilliseconds(time_string)/1000) + 60
                //   console.log(`Time until recheck ${time_in_milliseconds}`)
                // }, 1000)

                let requestCheckingTimes = JSON.stringify({
                  action: "fridgetemps",
                  fridgeAction: "requestCheckingTimes"
                })
                setTimeout(() => {
                  console.log("re requesting check times 1")
                  sendMessageToWebsocket(requestCheckingTimes)
                }, time_in_milliseconds + 3000)
              }

              setCheckingTimesForGroups(payload)
              break



            case 'returnFridgeGroups':
              setGroups(payload)
              //console.log('🐣')
              //console.log(JSON.stringify(payload))
              let newFridges = {}
              for (const [key, group] of Object.entries(payload)) {
                //@ts-ignore
                const thisGroupFridges = group['Fridges']
                newFridges = { ...newFridges, ...thisGroupFridges }
              }
              setFridges(newFridges)
              break

            case 'returnMultiTableData':
              const newMultiTableData = { ...tableDataRef.current }
              for (const [newTableName, newTableData] of Object.entries(payload)) {
                //@ts-ignore
                newMultiTableData[newTableName] = newTableData
              }
              // console.log('🦋')
              // console.log(JSON.stringify(newMultiTableData))
              setTableData(newMultiTableData)
              break

            case 'returnLatestBuildVersion':
              const remote_build_number = payload['version']
              // console.log(`Latest version from server ${remote_build_number}`)
              fetch('./build_number.txt').then(response => {
                if (response.ok) {
                  return response.text(); // Read the response as text
                }
                throw new Error('Failed to fetch file');
              })
                .then(local_build_number => {
                  if (`${local_build_number}` != `${remote_build_number}`) {
                    // console.log(`server says there is a newer app version: local ${local_build_number} / remote ${remote_build_number}`)
                    const last_upgrade_attempt = window.localStorage.getItem("last_upgrade_attempt")
                    const upgrade_attempt_data = JSON.stringify({
                      remote_build_number: remote_build_number,
                      time_stamp: `${Date.now()}`
                    })

                    if (last_upgrade_attempt == null) {
                      window.localStorage.setItem("last_upgrade_attempt", upgrade_attempt_data)
                      window.location.reload();
                    }

                    const last_upgrade_attempt_decoded = JSON.parse(last_upgrade_attempt || '{}')
                    if (last_upgrade_attempt_decoded['remote_build_number'] != remote_build_number) {
                      window.localStorage.setItem("last_upgrade_attempt", upgrade_attempt_data)
                      window.location.reload()
                    }
                    const last_attempt_seconds_ago = (Date.now() - last_upgrade_attempt_decoded['time_stamp']) / 1000
                    // console.log(`Tried this upgrade ${last_attempt_seconds_ago} seconds ago`)
                    if (last_attempt_seconds_ago > 300) {
                      window.localStorage.setItem("last_upgrade_attempt", upgrade_attempt_data)
                      window.location.reload()
                    }
                  }
                }) // Set the file content to state
                .catch(error => console.error('Error fetching file:', error));

              break

            case 'returnSingleTableData':
              const newKey = Object.keys(payload)[0]
              const newValue = payload[newKey]
              const newTableData = { ...tableDataRef.current }
              newTableData[newKey] = newValue
              setTableData(newTableData)
              break



            case 'tableUpdateItemAdded':
            case 'tableUpdateItemModified':
              //console.log(`🐯 update item received`)
              const tableToUpdate = payload['tableName']
              const itemToUpdate = payload['keys'][Object.keys(payload['keys'])[0]]
              const updatedTableData = { ...tableDataRef.current }
              updatedTableData[tableToUpdate] ||= {}
              updatedTableData[tableToUpdate][itemToUpdate] = payload['newRecord']
              //console.log(`🐯 setting to ${JSON.stringify(updatedTableData)}`)
              setTableData(updatedTableData)
              break
            case 'tableUpdateItemRemoved':
              const tableToRemoveItemFrom = payload['tableName']
              const itemToRemoveKey = payload['keys'][Object.keys(payload['keys'])[0]]
              const tableDataWithItemRemoved = { ...tableDataRef.current }
              if (tableDataWithItemRemoved[tableToRemoveItemFrom] && tableDataWithItemRemoved[tableToRemoveItemFrom][itemToRemoveKey]) {
                delete tableDataWithItemRemoved[tableToRemoveItemFrom][itemToRemoveKey]
              }

              setTableData(tableDataWithItemRemoved)
              break

            case 'loginFailed':
              setLoggedInState('failed')
              localStorage.removeItem('localIdToken')
              localStorage.removeItem('selectedOrgId')
              localStorage.removeItem('localAccessToken')
              localStorage.removeItem('guestLogin')
              //window.location.href = loginUrl
              break

            case 'loginOKButNoOrganisation':
              window.localStorage.setItem('loggedInNoOrgId', data)
              window.location.href = '/noorg'
              break

            case 'addItemResponse':
              break
            case 'subscriptionExpired':
              setSubscriptionExpired(true)
              break
            case 'returnVapidKeyForWebook':
              const public_key = JSON.parse(data)['payload']['publicKey']
              setWebPushPublicKey(public_key)
              break
            case 'savedTrainingImage':
              break
            // handled by pubsub
            case 'addItemSignedUploadURL':
            case 'temperatureReadingSaved':
              break
            // case 'fridgeReadingUpdated':
            //   break
            default:
              console.log(`A message has been received but the action is invalid. ${data}`)
              break
          }
        }
      }
    }
  }

  // console.log(loggedInState)
  // console.log(tableData)

  return <div className={`flex flex-col min-h-screen justify-between`}>
    <div className={`${brandstyles.contentbgcolor} flex flex-col flex-grow`}>

      <GlobalContext.Provider value={{
        brandstyles,
        tableData,
        userData,
        fridges,
        currentOrganisation,
        switchCurrentOrganisation,
        sendMessageToWebsocket
      }}>


        {subscriptionExpired && <SubscriptionExpired logoutUrl={logoutUrl} />}
        {subscriptionExpiringWithoutPayment && <SubscriptionExpiring
          setSubscriptionExpiringWithoutPayment={setSubscriptionExpiringWithoutPayment}
        />}

        <Router>
          <Routes>
            <Route
              path='/*'
              element={
                (
                  loggedInState === 'loggedIn' ||
                  loggedInState === 'guest' ||
                  loggedInState === 'orgUnknown'
                ) ? <>

                    {webPushPermissionState === 'user_in_trial' && <button onClick={() => { setWebPushPermissionState('permission_requested') }}>Click to get push notifications</button>}
                    <FullScreenFridgeScanner
                      socket={socket}
                      idToken={idToken}
                      accessToken={accessToken}
                      setFridges={setFridges}
                      lastCodeDetected={lastCodeDetected}
                      setLastCodeDetected={setLastCodeDetected}
                      currentGroupId={currentGroupId}
                      checkingTimesForGroups={checkingTimesForGroups}
                      groups={groups}
                      setCurrentGroupId={setCurrentGroupId}
                      aiImageWorker={aiImageWorker}
                      logoutUrl={logoutUrl}
                      loginUrl={loginUrl}
                      loggedInState={loggedInState}
                      loggedInStateRef={loggedInStateRef}
                      setLoggedInState={setLoggedInState}
                      setUserData={setUserData}
                      unknownCodeLoginOptions={unknownCodeLoginOptions}
                      setAssetToFindOrgFrom={setAssetToFindOrgFrom}
                      assetToFindOrgFrom={assetToFindOrgFrom}
                      pauseCodeDetection={subscriptionExpired || subscriptionExpiringWithoutPayment ? true : false}

                    /> </> :
                  <Home
                    loginUrl={loginUrl}
                    loggedInState={loggedInState}
                    assetToFindOrgFrom={assetToFindOrgFrom}
                  />
              } />
          </Routes>
        </Router>
      </GlobalContext.Provider>
    </div>
  </div>
}

export default App
